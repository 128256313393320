@import 'styles/variables.scss';

.bottom {
  grid-column: span 2 / span 2;

  @media (min-width: $screens-sm) {
    grid-column: span 1 / span 1;
  }
  @media (min-width: $screens-md) {
    grid-column: span 3 / span 3;
  }
  @media (min-width: $screens-lg) {
    grid-column: span 5 / span 5;
  }
}

.bottomGrid {
  display: grid;
  color: var(--color-lightGrey);
  font-size: var(--text-xs-size);
  text-align: center;

  @media (min-width: $screens-sm) {
    text-align: left;
  }
  @media (min-width: $screens-md) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    text-align: center;
  }
  @media (min-width: $screens-lg) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.bottomGridText {
  font-weight: 700;
  margin-bottom: var(--spacing-3);

  @media (min-width: $screens-sm) {
    margin-bottom: var(--spacing-1);
  }
}

.certified {
  margin-bottom: var(--spacing-6);

  @media (min-width: $screens-md) {
    grid-column: span 2 / span 2;
  }
  @media (min-width: $screens-lg) {
    grid-column: span 1 / span 1;
  }
}

.certifiedImage {
  object-fit: contain;
  width: var(--spacing-32);
}

.certifiedImages {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-1);

  @media (min-width: $screens-sm) {
    justify-content: flex-start;
  }
  @media (min-width: $screens-md) {
    justify-content: center;
  }
  @media (min-width: $screens-lg) {
    height: var(--spacing-24);
  }
}

.certifiedImageWrapper {
  margin-bottom: var(--spacing-2);
  margin-right: var(--spacing-2);

  @media (min-width: $screens-md) {
    margin-bottom: 0;
  }
}

.column {
  padding-top: var(--spacing-8);

  @media (min-width: $screens-md) {
    padding-top: 0;
  }
}

.columnHeader {
  color: var(--color-white);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-lg-size);
  padding-bottom: var(--spacing-1);
  text-transform: uppercase;
}

.columnNavigation {
  color: var(--color-lightGrey);
  line-height: var(--line-height-relaxed);
}

.copyright {
  color: var(--color-lightGrey);
  font-size: var(--text-xs-size);
  margin-bottom: var(--spacing-2);
  text-align: center;

  @media (min-width: $screens-sm) {
    margin-bottom: 0;
  }

  @media (min-width: $screens-lg) {
    text-align: right;
  }
}

.divider {
  color: var(--color-lightGrey);
  padding-bottom: var(--spacing-0);
  padding-inline: var(--spacing-2);

  @media (min-width: $screens-md) {
    padding-bottom: var(--spacing-1);
    padding-right: 0;
  }
}

.footer {
  background: var(--color-siam);
  flex-direction: column;
  padding-bottom: var(--spacing-28);
  padding-top: var(--spacing-8);

  @media (min-width: $screens-md) {
    padding-bottom: var(--spacing-20);
    padding-top: var(--spacing-12);
  }
}

.grid {
  display: grid;
  gap: var(--spacing-3);
  grid-template-columns: repeat(2, minmax(0, 1fr));

  @media (min-width: $screens-md) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  @media (min-width: $screens-lg) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

.google {
  text-align: left;
}

.googleBased {
  color: var(--color-white);
  font-size: var(--text-xs-size);
}

.googleHeadline {
  color: var(--color-white);
  font-size: var(--text-sm-size);
}

.googleReview {
  display: flex;
  align-items: center;
  color: var(--textColor-darkOrange);
  font-size: var(--text-lg-size);
  font-weight: 700;
}

.imtj {
  object-fit: contain;
  width: var(--spacing-16);
}

.links {
  display: flex;
  font-size: var(--text-sm-size);
  justify-content: center;

  @media (min-width: $screens-lg) {
    justify-content: flex-end;
  }

  li {
    @media (min-width: $screens-md) {
      margin-right: var(--spacing-2);
    }
  }
}

.linksWrapper {
  margin-top: 0;
  padding-top: var(--spacing-2);

  @media (min-width: $screens-lg) {
    border-color: var(--color-tertiary-grey);
    margin-top: var(--spacing-2);
  }
}

.reduced .linksWrapper {
  margin-top: 0;
  padding-top: 0;
}

.logo {
  margin-bottom: 0;
  text-align: center;
  width: var(--spacing-64);

  @media (min-width: $screens-md) {
    margin-bottom: var(--spacing-4);
    text-align: left;
  }

  svg {
    width: 100%;
  }
}

.reduced .logo {
  margin-bottom: var(--spacing-4);

  @media (min-width: $screens-md) {
    margin-bottom: 0;
  }
}

.logoWrapper {
  display: flex;
  justify-content: center;

  @media (min-width: $screens-md) {
    justify-content: flex-start;
  }
}

.madeInGermany {
  display: flex;
  align-items: center;
  color: var(--color-lightGrey);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-sm-size);
  justify-content: center;
  margin-bottom: var(--spacing-6);
  text-transform: uppercase;

  @media (min-width: $screens-sm) {
    justify-content: flex-start;
  }
  @media (min-width: $screens-md) {
    justify-content: center;
  }
  @media (min-width: $screens-lg) {
    justify-content: flex-start;
  }

  span {
    letter-spacing: 0.05em;
  }
}

.madeInGermanyIcon {
  margin-right: var(--spacing-2);
  width: var(--spacing-4);
}

a.navigationItem {
  border-bottom: 1px solid transparent;
  font-size: var(--text-sm-size);
  color: var(--color-lightGrey);
  text-decoration: none;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;

  &:hover {
    border-color: var(--color-lightGrey);
    color: var(--color-white);
  }
}

.reviews {
  display: flex;
  justify-content: center;
  height: var(--spacing-24);
  margin-bottom: var(--spacing-1);

  @media (min-width: $screens-sm) {
    justify-content: flex-start;
  }
  @media (min-width: $screens-md) {
    justify-content: center;
  }
}

.socials {
  justify-content: center;

  @media (min-width: $screens-sm) {
    justify-content: flex-start;
  }
  @media (min-width: $screens-md) {
    justify-content: center;
  }
  @media (min-width: $screens-lg) {
    justify-content: flex-start;
  }
}

.socialsContainer {
  border-top: 1px solid var(--color-legacyGrey);
  margin-top: var(--spacing-8);
  padding-bottom: var(--spacing-7);
  padding-top: var(--spacing-8);

  @media (min-width: $screens-sm) {
    border-width: 0;
    padding-top: 0;
  }
  @media (min-width: $screens-md) {
    border-top: 1px solid var(--color-legacyGrey);
    margin-top: var(--spacing-10);
    padding-top: var(--spacing-8);
  }
}

.trustbox {
  margin-bottom: var(--spacing-1);

  @media (min-width: $screens-sm) {
    margin-bottom: var(--spacing-10);
  }
}

.trustboxWrapper {
  margin-right: var(--spacing-2);
  width: var(--spacing-32);
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: $screens-xl;
  padding: var(--spacing-2) var(--spacing-3);

  @media (min-width: $screens-md) {
    padding-inline: var(--spacing-4);
  }
}

.reduced {
  padding-block: var(--spacing-8);

  @media (min-width: $screens-md) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (min-width: $screens-lg) {
    padding-block: var(--spacing-4);
  }
}
