.controlGroup {
  margin-bottom: var(--spacing-4);
  &.hasWrapper {
    margin-bottom: 0;
  }
}

.label {
  font-size: var(--text-base-size);
  font-weight: bold;
}

.input {
  border-radius: 0.25rem;
  padding: var(--spacing-2) var(--spacing-4);
  width: 100%;
  outline: 2px solid transparent;
  outline-offset: 2px;
  font-size: var(--text-base-size);
  line-height: var(--line-height-7);

  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);

  border: 2px solid var(--color-bluishGrey);
  &.hasError:not(.noBorder) {
    border-color: var(--color-qunoRed);
  }

  &.useGreyBackground {
    background: var(--color-bluishGrey);
    color: var(--color-greyDarkest);
  }

  &:focus {
    background: var(--color-white);
    border-color: var(--color-secondary);
  }
}

.errorMessage {
  font-size: var(--text-sm-size);
  color: var(--color-qunoRed);
  margin-top: var(--spacing-1);
}

.subLabel {
  font-size: var(--text-sm-size);
  color: var(--textColor-primary);
}
