@import 'styles/variables.scss';

.extended {
  display: flex;
  align-items: center;
}

.header {
  background-color: var(--color-white);
  z-index: 30;
}

.inner {
  display: flex;
  align-items: center;
  height: 80px;
  justify-content: center;
  margin: 0 auto;
  max-width: $screens-xl;
  padding: var(--spacing-5);

  @media (min-width: $screens-md) {
    padding-block: var(--spacing-5);
    padding-inline: var(--spacing-4);
  }

  @media (min-width: $screens-lg) {
    justify-content: space-between;
  }
}

.logo {
  order: 9999;
  padding-top: var(--spacing-1);

  @media (min-width: $screens-lg) {
    order: -9999;
  }

  svg {
    height: var(--spacing-8);
    width: var(--spacing-44);

    @media (min-width: $screens-lg) {
      width: var(--spacing-56);
    }
  }
}

.mobileCurrency {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-2);
  padding-block: var(--spacing-2);
  position: relative;

  @media (min-width: $screens-lg) {
    justify-content: flex-start;
    margin-bottom: 0;
    padding-block: 0;
  }
}

.mobileLanguage {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-4);
  padding-block: var(--spacing-2);
  position: relative;

  @media (min-width: $screens-lg) {
    justify-content: flex-start;
    margin-top: 0;
    padding-block: 0;
    padding-right: var(--spacing-6);
  }
}

.mobileMenuButton {
  left: 0;
  margin-left: var(--spacing-4);
  padding-left: 0;
  position: absolute;

  @media (min-width: $screens-lg) {
    display: none;
  }

  svg {
    height: var(--spacing-5);
    width: var(--spacing-6);
  }
}

.mobileWrapper {
  margin-top: var(--spacing-24);
  padding-top: var(--spacing-12);
}

.search {
  display: flex;
  align-items: center;
  color: var(--textColor-primary);
  cursor: pointer;
  font-family: var(--fontFamily-netto);
  position: absolute;
  right: 0;
  text-transform: uppercase;

  @media (min-width: $screens-lg) {
    margin-right: var(--spacing-6);
    position: relative;
  }
}

.searchSvg {
  width: var(--spacing-20);

  @media (min-width: $screens-lg) {
    margin-right: var(--spacing-2);
    width: var(--spacing-4);
  }
}

.searchLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchText {
  display: none;
  font-weight: 700;
  padding-block: var(--spacing-2);

  @media (min-width: $screens-lg) {
    display: block;
  }
}

.wrapper {
  background-color: var(--color-white);
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color),
    0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  position: relative;
  z-index: 40;
}
