@import 'styles/variables.scss';
@import 'styles/functions.scss';

.SlideOutContent {
  background-color: var(--color-white);
  max-height: 100vh;
  overflow-y: auto;
  position: fixed;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  z-index: 30;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: rem(7px);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: var(--spacing-1);
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  &.leftPlacement {
    bottom: 0;
    left: 0;
    top: 0;
    transform: translateX(-100%);
  }

  &.rightPlacement {
    bottom: 0;
    right: 0;
    top: 0;
    transform: translateX(100%);
  }

  &.bottomPlacement {
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100%);
    width: 100%;
  }

  &.topPlacement {
    left: 0;
    right: 0;
    top: 0;
    transform: translateY(-100%);
    width: 100%;
  }

  &.isOpen {
    transform: translate(0%);
  }
}

$size: 23px;

.closeBtn {
  right: rem($size);
  top: rem($size);

  @media (min-width: $screens-md) {
    right: rem($size + 17);
    top: rem($size + 5);
  }
}

/**
 * Fade transition classes
 */
.fade {
  opacity: 0;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transition-duration: 150ms;
  will-change: opacity;
}

.fadeEnter {
  opacity: 0;
  transition-duration: 300ms;
}

.fadeEnterActive {
  opacity: 0.75;
}

.fadeEnterDone {
  opacity: 0.75;
  pointer-events: auto;
}

.fadeExit {
  transition-duration: 500ms;
}

.fadeExitActive {
  opacity: 0;
}

.fadeExitDone {
  opacity: 0;
}
