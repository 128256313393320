.mainNavigation li a.animate {
  &:before {
    content: ' ';
    background-color: var(--color-primary);
    bottom: var(--spacing-1);
    height: 1px;
    left: 0;
    margin-left: var(--spacing-1);
    position: absolute;
    right: 0;
    transform: scaleX(0);
    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 300ms;
    visibility: hidden;
  }

  &:hover:before {
    transform: scaleX(1);
    visibility: visible;
  }
}
