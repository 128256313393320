@import 'styles/variables.scss';

.container {
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary);
  border-radius: var(--spacing-1);
  color: var(--textColor-primary);
  font-family: var(--fontFamily-netto);
  font-size: var(--text-2xl-size);
  justify-content: center;
  padding: var(--spacing-1) var(--spacing-2);
  width: var(--spacing-36);

  @media (min-width: $screens-lg) {
    border-width: 0;
    font-size: var(--text-base-size);
    padding: 0;
    width: auto;
  }
}

.select {
  background-color: var(--color-white);
  border-radius: var(--spacing-2);
  box-shadow: 0 0 var(--spacing-1-5) var(--spacing-0-5) var(--color-silver);
  overflow: hidden;
}

.selectContainer {
  bottom: 85%;
  padding-top: var(--spacing-4);
  position: absolute;
  z-index: 10;

  @media (min-width: $screens-lg) {
    bottom: 0;
    top: var(--spacing-12);
  }
}
